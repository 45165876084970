import { type Settings as LayoutSettings } from '@ant-design/pro-layout';
import type { RunTimeLayoutConfig, RuntimeAntdConfig } from '@umijs/max';
import { history } from '@umijs/max';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import { LicenseModal, LicenseAlert } from './components/LicenseAlert';
import { initialState as queryInitialState, currentUser as queryCurrentUser } from './services/api';
import type { API } from './services/typings';
import { t, fallbackEnUs, keyAccessToken, urlEncodeRecords } from './global';
import defaultSettings from '../config/defaultSettings';
import NoAccessPage from '@/pages/NoAccessPage';
import NoFoundPage from '@/pages/404';
import { message, theme } from 'antd';
import queryString from 'query-string';
import { AUTH_RES_TYPE_EMAIL_CHECK, AUTH_RES_TYPE_TFA_CHECK } from './pages/user/Login';

const loginPath = '/user/login';
const verifyPath = '/user/verify';
const unsubscribeEmailPath = '/user/unsubscribe-email';

export async function getInitialState(): Promise<{
  layoutSettings?: Partial<LayoutSettings>;
  user?: API.User;
  team?: API.Team;
  group?: API.GroupListItem;
  license?: API.LicenseState;
  settings?: API.ConfigEntry[];
  loading?: boolean;
  version?: string;
  fetchInitialState?: () => Promise<
    | {
      user: API.User;
      team: API.Team;
      group: API.GroupListItem;
      license?: API.LicenseState;
      settings?: API.ConfigEntry[];
      version?: string;
    }
    | undefined
  >;
}> {
  const fetchInitialState = async () => {
    if (
      history.location.pathname == verifyPath ||
      history.location.pathname == unsubscribeEmailPath
    ) {
      return undefined;
    }

    const query = queryString.parse(history.location.search);
    if (query?.token) {
      if (!!localStorage.getItem('autoLogin')) {
        localStorage.setItem(keyAccessToken, query.token as string);
      } else {
        sessionStorage.setItem(keyAccessToken, query.token as string);
      }
      location.href = location.origin + location.pathname;
    } else if (query?.tfa_type) {
      if (query?.tfa_type == AUTH_RES_TYPE_EMAIL_CHECK) {
        const queryParams = {
          tfa_type: 'email_check',
          username: query?.username,
          email: query?.email,
          secret: query?.secret,
        } as any;
        location.href = `${location.origin}/#/user/login?${urlEncodeRecords(queryParams)}`;
      } else if (query?.tfa_type == AUTH_RES_TYPE_TFA_CHECK) {
        const queryParams = {
          tfa_type: 'tfa_check',
          username: query?.username,
          secret: query?.secret,
        } as any;
        location.href = `${location.origin}/#/user/login?${urlEncodeRecords(queryParams)}`;
      } else {
        // unreachable
        message.error('Unknown 2FA type, ' + query?.tfa_type);
        return undefined;
      }
    } else if (query?.error) {
      message.error(query.error as string);
      history.push(loginPath);
    }
    try {
      const msg = await queryInitialState();
      return msg;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };
  if (
    history.location.pathname !== loginPath &&
    history.location.pathname !== verifyPath &&
    history.location.pathname !== unsubscribeEmailPath
  ) {
    await queryCurrentUser(); // this can not be skipped, because it has some special logic
    const initialState = await fetchInitialState();
    return {
      fetchInitialState: fetchInitialState,
      user: initialState?.user,
      team: initialState?.team,
      group: initialState?.group,
      license: initialState?.license,
      settings: initialState?.settings,
      layoutSettings: defaultSettings,
      version: initialState?.version,
    };
  }
  return {
    fetchInitialState: fetchInitialState,
    layoutSettings: defaultSettings,
  };
}

export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    unAccessible: fallbackEnUs(<NoAccessPage />),
    noFound: fallbackEnUs(<NoFoundPage />),
    rightContentRender: () => fallbackEnUs(<RightContent />),
    disableContentMargin: true,
    footerRender: () => fallbackEnUs(<Footer />),
    onPageChange: () => {
      const { location } = history;
      if (location.pathname == verifyPath || location.pathname == unsubscribeEmailPath) {
        return;
      }
      if (!initialState?.user && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },
    menuHeaderRender: undefined,
    childrenRender: (children) => {
      defaultSettings.title = t('app.title', true, defaultSettings.title as string) as string;
      // if (initialState?.loading) return <PageLoading />;
      return fallbackEnUs(
        <>
          {initialState?.user?.is_admin &&
            <>
              <LicenseModal once={true} />
              <LicenseAlert />
            </>
          }
          {children}
        </>
      );
    },
    // https://procomponents.ant.design/components/layout#menu
    defaultCollapsed: localStorage.getItem('nav-collapsed') === 'true',
    breakpoint: false,
    onCollapse: (collapsed: boolean) => {
      if (collapsed) {
        localStorage.setItem('nav-collapsed', 'true');
      } else {
        localStorage.removeItem('nav-collapsed');
      }
    },
    ...initialState?.layoutSettings,
  };
};

export const antd: RuntimeAntdConfig = (memo) => {
  memo.theme ??= {};
  // Same as system theme because react-use-color-mode will initialize the same theme as system
  memo.theme.algorithm = window.matchMedia('(prefers-color-scheme: dark)').matches ? theme.darkAlgorithm : theme.defaultAlgorithm;

  memo.appConfig = {
    message: {
      maxCount: 3,
    }
  }

  return memo;
};
