export default {
  'pages.layouts.userLayout.title':
    'Ant Design é a especificação de web design mais influente no distrito de Xihu',
  'pages.login.accountLogin.tab': 'Login da conta',
  'pages.login.accountLogin.errorMessage': 'usuário/senha incorreto(admin/ant.design)',
  'pages.login.username.placeholder': 'Usuário: admin or user',
  'pages.login.username.required': 'Por favor insira seu usuário!',
  'pages.login.password.placeholder': 'Senha: ant.design',
  'pages.login.password.required': 'Por favor insira sua senha!',
  'pages.login.phoneLogin.tab': 'Login com Telefone',
  'pages.login.phoneLogin.errorMessage': 'Erro de Código de Verificação',
  'pages.login.phoneNumber.placeholder': 'Telefone',
  'pages.login.phoneNumber.required': 'Por favor entre com seu telefone!',
  'pages.login.phoneNumber.invalid': 'Telefone é inválido!',
  'pages.login.captcha.placeholder': 'Código de Verificação',
  'pages.login.captcha.required': 'Por favor entre com o código de verificação!',
  'pages.login.phoneLogin.getVerificationCode': 'Obter Código',
  'pages.getCaptchaSecondText': 'seg(s)',
  'pages.login.rememberMe': 'Lembre-me',
  'pages.login.forgotPassword': 'Perdeu a Senha ?',
  'pages.login.submit': 'Enviar',
  'pages.login.loginWith': 'Login com :',
  'pages.login.registerAccount': 'Registra Conta',
  'pages.welcome.advancedComponent': 'Componente Avançado',
  'pages.welcome.link': 'Bem-vindo',
  'pages.welcome.advancedLayout': 'Layout Avançado',
  'pages.welcome.alertMessage': 'Componentes pesados mais rápidos e mais fortes foram lançados.',
  'pages.admin.subPage.title': 'Esta página só pode ser vista pelo Admin',
  'pages.admin.subPage.alertMessage':
    'O Umi ui foi lançado, bem-vindo ao usar o npm run ui para iniciar a experiência.',
};
