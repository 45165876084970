import React from 'react';
import { Tooltip } from 'antd';
import { useModel } from '@umijs/max';
import styles from './userSettings.less';
import { initialState as queryInitialState } from '@/services/api';
import { QuestionCircleOutlined } from '@ant-design/icons';
import type { API } from '@/services/typings';

export const isTfaEnabled = (statusTfa?: number) => [2, 3].includes(statusTfa ?? 0);

export const SettingsActionTooltipIcon = (title: string) => <Tooltip title={title}>
  <QuestionCircleOutlined style={{ fontSize: '11px', color: 'gray', cursor: 'help', marginLeft: '3px' }} />
</Tooltip>;

export type UserSettingsProps = {
  currentUser: API.User | undefined;
  refreshInitialState: () => Promise<void>;
  handleSwitchTab?: (tab: string) => void;
}

type UserSettingsViewProps = {
  componentCreator: (props: UserSettingsProps) => React.ReactNode;
  handleSwitchTab?: (tab: string) => void;
}

const UserSettingsView: React.FC<UserSettingsViewProps> = ({ componentCreator, handleSwitchTab }) => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const { user: currentUser, loading } = initialState!;

  const refreshInitialState = async () => {
    const current = await queryInitialState();
    await setInitialState((s) => ({
      ...s,
      ...current,
    }));
  }

  return (
    <div className={styles.baseView}>
      {loading ? null : (
        <div className={styles.left}>
          {componentCreator({currentUser, refreshInitialState, handleSwitchTab})}
        </div>
      )}
    </div>
  );
};

export default UserSettingsView;
