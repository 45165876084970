import Icon, { CustomIconComponentProps } from "@ant-design/compatible/lib/icon";
import { MappingAlgorithm, theme } from "antd";
import { ConfigProviderProps } from "antd/es/config-provider";
import { useEffect } from "react";
import { useColorMode } from "react-use-color-mode";
import { GlobalHeaderRightProps } from "./AvatarDropdown";
import { useAntdConfig, useAntdConfigSetter } from "@umijs/max";
const { darkAlgorithm, defaultAlgorithm } = theme;

// https://umijs.org/docs/max/antd
// https://github.com/umijs/umi/blob/master/examples/with-antd-5/pages/index.tsx


export const ThemeSwitchAction: React.FC<GlobalHeaderRightProps> = (props) => {
  const colorMode = useColorMode();
  const setAntdConfig = useAntdConfigSetter();
  const antdConfig = useAntdConfig();


  useEffect(() => {
    if (colorMode === 'light') {
      setDarkAlgorithm(false);
    } else if (colorMode === 'dark') {
      setDarkAlgorithm(true);
    }

  }, [colorMode])

  const setDarkAlgorithm = (dark: boolean) => {
    setAntdConfig({
      theme: {
        algorithm: [
          dark ? darkAlgorithm : defaultAlgorithm,
        ],
      },
    });
  }

  const isDark = checkHasAlgorithm(antdConfig, darkAlgorithm);

  const iconSize = "1.4em";
  const sunSvg = () => (
    <svg width={iconSize} height={iconSize} fill="currentColor" viewBox="0 0 1028 1024" >
      <path d="M514.133333 509.866667m-256 0a256 256 0 1 0 512 0 256 256 0 1 0-512 0Z" p-id="6112"></path>
      <path d="M514.133333 170.666667c-19.2 0-32-14.933333-32-32V29.866667c0-17.066667 12.8-29.866667 29.866667-29.866667h2.133333c17.066667 0 32 14.933333 32 32v108.8c0 14.933333-14.933333 29.866667-32 29.866667zM514.133333 1024c-19.2 0-32-14.933333-32-32v-108.8c0-17.066667 14.933333-32 32-32h2.133334c17.066667 0 32 14.933333 32 32v108.8c-2.133333 17.066667-17.066667 32-34.133334 32zM853.333333 509.866667c0-19.2 14.933333-32 32-32h108.8c17.066667 0 32 14.933333 32 32v2.133333c0 17.066667-14.933333 32-32 32h-108.8c-17.066667-2.133333-32-17.066667-32-34.133333zM0 509.866667c0-19.2 14.933333-32 32-32h108.8c17.066667 0 32 14.933333 32 32v2.133333c0 17.066667-14.933333 32-32 32H32c-17.066667-2.133333-32-17.066667-32-34.133333zM742.4 247.466667c-12.8-12.8-12.8-32-2.133333-44.8l76.8-76.8c12.8-12.8 32-12.8 44.8 0V128c12.8 12.8 12.8 32 0 44.8l-76.8 76.8c-10.666667 10.666667-32 10.666667-42.666667-2.133333zM138.666667 851.2c-12.8-12.8-12.8-34.133333-2.133334-44.8l76.8-76.8c12.8-12.8 32-12.8 44.8 0l2.133334 2.133333c12.8 12.8 12.8 32 0 44.8L183.466667 853.333333c-12.8 10.666667-32 10.666667-44.8-2.133333zM740.266667 727.466667c12.8-12.8 34.133333-12.8 44.8-2.133334l76.8 76.8c12.8 12.8 12.8 32 0 44.8l-2.133334 2.133334c-12.8 12.8-32 12.8-44.8 0l-76.8-76.8c-10.666667-12.8-10.666667-32 2.133334-44.8zM136.533333 123.733333c12.8-12.8 34.133333-12.8 44.8-2.133333l76.8 76.8c12.8 12.8 12.8 32 0 44.8l-2.133333 2.133333c-12.8 12.8-32 12.8-44.8 0L136.533333 168.533333c-10.666667-12.8-10.666667-32 0-44.8z" p-id="6113"></path>
    </svg>
  );
  const SunIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={sunSvg} {...props} />
  );
  const moonSvg = () => (
    <svg width={iconSize} height={iconSize} fill="currentColor" viewBox="0 0 1028 1024" >
      <path d="M923.306667 554.666667a42.666667 42.666667 0 0 0-44.8-5.973334 343.466667 343.466667 0 0 1-143.786667 31.146667 347.733333 347.733333 0 0 1-347.306667-345.6 366.506667 366.506667 0 0 1 10.666667-85.333333A42.666667 42.666667 0 0 0 341.333333 100.693333a432.64 432.64 0 1 0 597.333334 498.773334 42.666667 42.666667 0 0 0-15.36-44.8z m-405.333334 285.44A347.306667 347.306667 0 0 1 302.08 222.72v11.52a433.066667 433.066667 0 0 0 432.64 432.64 417.706667 417.706667 0 0 0 89.6-9.386667 346.026667 346.026667 0 0 1-306.346667 184.32z" p-id="4921"></path>
    </svg >
  );
  const MoonIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={moonSvg} {...props} />
  );

  return <span
    className={props.actionClassName}
    onClick={() => setDarkAlgorithm(!isDark)} >
    {!isDark ? <SunIcon /> : <MoonIcon />}
  </span>;

};

export const checkHasAlgorithm = (
  antdConfig: ConfigProviderProps,
  algorithm: MappingAlgorithm,
) => {
  if (!antdConfig?.theme?.algorithm) {
    return false;
  }
  const nowAlgorithm = Array.isArray(antdConfig?.theme?.algorithm)
    ? antdConfig?.theme?.algorithm
    : [antdConfig?.theme?.algorithm];
  return nowAlgorithm?.includes(algorithm);
};
