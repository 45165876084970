export default {
  'pages.layouts.userLayout.title': 'Ant Designは、西湖区で最も影響力のあるWebデザイン仕様です。',
  'pages.login.accountLogin.tab': 'アカウントログイン',
  'pages.login.accountLogin.errorMessage':
    'ユーザー名/パスワードが正しくありません(admin/ant.design)',
  'pages.login.username.placeholder': 'ユーザー名：adminまたはuser',
  'pages.login.username.required': 'ユーザー名を入力してください！',
  'pages.login.password.placeholder': 'パスワード：ant.design',
  'pages.login.password.required': 'パスワードを入力してください！',
  'pages.login.phoneLogin.tab': '電話ログイン',
  'pages.login.phoneLogin.errorMessage': '検証コードエラー',
  'pages.login.phoneNumber.placeholder': '電話番号',
  'pages.login.phoneNumber.required': '電話番号を入力してください！',
  'pages.login.phoneNumber.invalid': '電話番号が無効です！',
  'pages.login.captcha.placeholder': '確認コード',
  'pages.login.captcha.required': '確認コードを入力してください！',
  'pages.login.phoneLogin.getVerificationCode': '確認コードを取得',
  'pages.getCaptchaSecondText': '秒',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'パスワードをお忘れですか？',
  'pages.login.submit': 'ログイン',
  'pages.login.loginWith': 'その他のログイン方法：',
  'pages.login.registerAccount': 'アカウント登録',
  'pages.welcome.advancedComponent': '高度なコンポーネント',
  'pages.welcome.link': 'ようこそ',
  'pages.welcome.advancedLayout': '高度なレイアウト',
  'pages.welcome.alertMessage': 'より高速で強力な頑丈なコンポーネントがリリースされました。',
  'pages.admin.subPage.title': 'このページは管理者のみが表示できます',
  'pages.admin.subPage.alertMessage':
    'Umi uiがリリースされました。npm run uiを使用して体験してください。'
};
