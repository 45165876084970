import { Col, Progress, Row } from 'antd';
import { FC } from 'react';
import styles from './index.less';
type PwdStrengthLevel = 0 | 1 | 2 | 3;
interface IPwdStrengthProps {
  pwdStrength: PwdStrengthLevel;
}
const PwdStrength: FC<IPwdStrengthProps> = ({ pwdStrength }) => {
  return (
    <div className={styles.passwordStrongBox}>
      <Row gutter={12}>
        {false && <span className={styles.passWord}>Password Strength</span>}
        <Col span={3}>
          <Progress className={styles.weak} percent={pwdStrength > 0 ? 100 : 0} showInfo={false} />
        </Col>
        <Col span={3}>
          <Progress
            className={styles.middle}
            percent={pwdStrength > 1 ? 100 : 0}
            showInfo={false}
          />
        </Col>
        <Col span={3}>
          <Progress
            className={styles.strong}
            percent={pwdStrength > 2 ? 100 : 0}
            showInfo={false}
          />
        </Col>
        <span className="passStrong">
          {pwdStrength === 1 && 'Weak'}
          {pwdStrength === 2 && 'Medium'}
          {pwdStrength === 3 && 'Strong'}
        </span>
      </Row>
    </div>
  );
};
const pattern_1 = /^.*([0-9])+.*$/i;
const pattern_2 = /[a-z]/;
const pattern_3 = /[A-Z]/;
const pattern_4 =
  /[`~!@#$%^&*()\-_+=\\|{}':;\",\[\].<>\/?~！@#￥%……&*（）——+|{}【】‘；：”“’。，、？]/;
const getPwdStrength = (pwd: string): PwdStrengthLevel => {
  let level = 0;
  if (pwd) {
    pwd = pwd.trim();
    if (pwd.length >= 6) {
      if (pattern_1.test(pwd)) {
        level++;
      }
      if (pwd.length > 10) {
        level++;
      }
      if (pattern_2.test(pwd) || pattern_3.test(pwd)) {
        level++;
      }
      if (pattern_4.test(pwd)) {
        level++;
      }
      if (level > 3) {
        level = 3;
      }
    }
  }

  return level as PwdStrengthLevel;
};
export { PwdStrength, PwdStrengthLevel, getPwdStrength };
