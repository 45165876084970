export default {
  'component.globalHeader.search': 'অনুসন্ধান করুন',
  'component.globalHeader.search.example1': 'অনুসন্ধান উদাহরণ ১',
  'component.globalHeader.search.example2': 'অনুসন্ধান উদাহরণ ২',
  'component.globalHeader.search.example3': 'অনুসন্ধান উদাহরণ ৩',
  'component.globalHeader.help': 'সহায়তা',
  'component.globalHeader.notification': 'বিজ্ঞপ্তি',
  'component.globalHeader.notification.empty': 'আপনি সমস্ত বিজ্ঞপ্তি দেখেছেন।',
  'component.globalHeader.message': 'বার্তা',
  'component.globalHeader.message.empty': 'আপনি সমস্ত বার্তা দেখেছেন।',
  'component.globalHeader.event': 'ঘটনা',
  'component.globalHeader.event.empty': 'আপনি সমস্ত ইভেন্ট দেখেছেন।',
  'component.noticeIcon.clear': 'সাফ',
  'component.noticeIcon.cleared': 'সাফ করা হয়েছে',
  'component.noticeIcon.empty': 'বিজ্ঞপ্তি নেই',
  'component.noticeIcon.view-more': 'আরো দেখুন',
};
