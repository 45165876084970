// @ts-ignore
/* eslint-disable */
import { request as request0, history } from '@umijs/max';
import { API } from './typings';
import { keyAccessToken } from '@/global';

async function request<T>(url: string, options: any, not_check_err?: boolean): Promise<T> {
  try {
    options['skipErrorHandler'] = true;
    let out: any = await request0<T>(url, options);
    if (out && !not_check_err && out['error']) {
      throw out['error'];
    }
    return out as any as T;
  } catch (err: any) {
    if (err.response?.status == 401 && !history.location.pathname?.startsWith('/user')) {
      localStorage.removeItem(keyAccessToken);
      sessionStorage.removeItem(keyAccessToken);
      window.location.reload();
    }
    if (err.response?.status != 401 && err.response?.statusText) {
      throw (err.response?.statusText);
    }
    throw err;
  }
}

export const getToken = () => localStorage.getItem(keyAccessToken) || sessionStorage.getItem(keyAccessToken);

function getHeaders() {
  const headers: any = { 'Content-Type': 'application/json' };
  const token = getToken();
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  return headers;
}

function wrapFuzzyQuery(params: { [key: string]: any }, keys: string[]) {
  for (const key of keys) {
    if (params[key] != undefined && typeof (params[key]) == 'string' && params[key].indexOf('%') == -1) {
      const k = params[key].trim();
      if (k.length > 0) {
        if (k === '-') {
          params[key] = k;
        } else {
          params[key] = '%' + k + '%';
        }
      } else {
        delete params[key];
      }
    }
  }
  return params;
}

export async function currentUser(options?: { [key: string]: any }) {
  const headers = getHeaders();
  if (!headers['Authorization']) {
    return;
  }
  return request<API.User>('/api/currentUser', {
    method: 'POST',
    headers,
    ...(options || { data: {} }),
  });
}

export async function initialState(options?: { [key: string]: any }) {
  const headers = getHeaders();
  if (!headers['Authorization']) {
    return;
  }
  return request<{
    version: string,
    user: API.User;
    team: API.Team,
    group: API.GroupListItem,
    license?: API.LicenseState // null when user is not admin
    settings?: API.ConfigEntry[],
  }>('/api/initialState', {
    method: 'POST',
    headers,
    ...(options || { data: {} }),
  });
}

export async function outLogin(payload: { [key: string]: string | null }, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/logout', {
    method: 'POST',
    headers: getHeaders(),
    data: payload,
    ...(options || {}),
  });
}

export async function getCfg() {
  return request<Record<'port' | 'key', string>>('/api/cfg', {
    method: 'GET',
    headers: getHeaders(),
  });
}


export async function loginOptions() {
  return request<string[]>('/api/login-options', {
    method: 'GET',
    headers: getHeaders(),
  });
}

export async function login(body: API.LoginRequest, options?: { [key: string]: any }) {
  return request<API.LoginResponse>('/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function oidcAuth(body: API.OidcAuth, options?: { [key: string]: any }) {
  const resp = await request<API.OidcAuthUrl>('/api/oidc/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
  window.location.href = resp.url;
}

export async function getNotices(options?: { [key: string]: any }) {
  return request<API.NoticeIconList>('/api/notices', {
    method: 'GET',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function connectAudits(
  params: {
    current?: number;
    pageSize?: number;
    created_at?: any;
  },
  options?: { [key: string]: any },
) {
  let t = params['created_at'];
  if (t) {
    params['created_at'] = new Date(t).toISOString().replace('T', ' ').replace('Z', '');
  }
  params = wrapFuzzyQuery(params, ['remote', 'remote_device_name']);
  return request<API.ConnAuditList>('/api/audits/conn', {
    method: 'GET',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function fileTransferAudits(
  params: {
    current?: number;
    pageSize?: number;
    created_at?: any;
  },
  options?: { [key: string]: any },
) {
  let t = params['created_at'];
  if (t) {
    params['created_at'] = new Date(t).toISOString().replace('T', ' ').replace('Z', '');
  }
  params = wrapFuzzyQuery(params, ['remote', 'remote_device_name']);
  return request<API.FileAuditList>('/api/audits/file', {
    method: 'GET',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function alarmAudits(
  params: {
    current?: number;
    pageSize?: number;
    created_at?: any;
  },
  options?: { [key: string]: any },
) {
  let t = params['created_at'];
  if (t) {
    params['created_at'] = new Date(t).toISOString().replace('T', ' ').replace('Z', '');
  }
  params = wrapFuzzyQuery(params, ['account', 'device', 'report_device_name']);
  return request<API.AlarmAuditList>('/api/audits/alarm', {
    method: 'GET',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function consoleAudits(
  params: {
    current?: number;
    pageSize?: number;
    created_at?: any;
  },
  options?: { [key: string]: any },
) {
  let t = params['created_at'];
  if (t) {
    params['created_at'] = new Date(t).toISOString().replace('T', ' ').replace('Z', '');
  }
  params = wrapFuzzyQuery(params, ['operator']);
  return request<API.ConsoleAuditList>('/api/audits/console', {
    method: 'GET',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function requestDisconnectConn(guid: String) {
  return request<API.DisconnectResult>('/api/audit/disconnect', {
    method: 'POST',
    headers: getHeaders(),
    data: {
      audit_guid: guid,
    },
  });
}

export async function users(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  params = wrapFuzzyQuery(params, ['name', 'email', 'group_name']);
  return request<API.UserList>('/api/user-list', {
    // TODO /api/users not work in dev
    method: 'GET',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function groups(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
  no_fruzzy?: boolean,
) {
  if (!no_fruzzy) {
    params = wrapFuzzyQuery(params, ['name']);
  }
  return request<API.GroupList>('/api/groups', {
    method: 'GET',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function tokens(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.UserSession[]>('/api/tokens', {
    method: 'GET',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function addToken(data: API.TokenPayload) {
  return request<string>('/api/tokens', {
    method: 'POST',
    headers: getHeaders(),
    data,
  });
}

export async function deleteToken(id: string) {
  return request<API.GroupList>('/api/tokens/' + id, {
    method: 'delete',
    headers: getHeaders(),
  });
}

export async function names(
  params: {
    table: string;
    pattern: string;
    limit: number;
    offset?: number;
  },
  options?: { [key: string]: any },
) {
  return request<string[]>('/api/names', {
    method: 'GET',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function createCrossGroupRule(data: API.CrossGrpPayload) {
  return request<undefined>('/api/cross-group/create-rule', {
    method: 'POST',
    headers: getHeaders(),
    data,
  });
}

export async function deleteCrossGroupRule(data: API.CrossGrpPayload) {
  return request<undefined>('/api/cross-group/delete-rule', {
    method: 'PUT',
    headers: getHeaders(),
    data,
  });
}

export async function getAllCrossGrpRules(data: { guid: string }) {
  return request<API.CrossGrpRules>('/api/cross-group/rules', {
    method: 'POST',
    headers: getHeaders(),
    data,
  });
}

export async function devices(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  params = wrapFuzzyQuery(params, [
    'id',
    'device_name',
    'device_username',
    'user_name',
    'group_name',
    'strategy_name',
    'user_strategy_name'
  ]);
  return request<API.DeviceList>('/api/peers', {
    method: 'GET',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function updateUser(options?: { [key: string]: any }) {
  return request<{ verification_email_sent?: boolean }>('/api/user', {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function updateUserVerify(payload: { type: string, user: string, secret: string }, options?: { [key: string]: any },) {
  return request<void>('/api/user/update-verify', {
    method: 'POST',
    headers: getHeaders(),
    data: payload,
    ...(options || {}),
  });
}

export async function updateGroup(options?: { [key: string]: any }) {
  return request<API.GroupListItem>('/api/group', {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function enableUsers(options?: { [key: string]: any }) {
  return request<API.ConnAuditListItem>('/api/enable-users', {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function addUser(options?: { [key: string]: any }) {
  return request<API.ConnAuditListItem>('/api/user', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function delUser(id: string, options?: { [key: string]: any }) {
  return request(`/api/user/${id}`, {
    method: 'DELETE',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function delPeer(guid: string, options?: { [key: string]: any }) {
  return request(`/api/devices/${guid}`, {
    method: 'DELETE',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function enableDevices(options?: { [key: string]: any }) {
  return request<API.ConnAuditListItem>('/api/enable-peers', {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function updateDevice(options?: { [key: string]: any }) {
  return request<API.ConnAuditListItem>('/api/peer', {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function updateAudit(options?: { [key: string]: any }) {
  return request<API.ConnAuditListItem>('/api/audit', {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function deleteGroups(options?: { [key: string]: any }) {
  return request<API.ConnAuditListItem>('/api/delete-groups', {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function addGroup(options?: { [key: string]: any }) {
  return request<API.ConnAuditListItem>('/api/group', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

/// Tests the `smtp config` in Team Info is valid or not.
/// Cheap test. Only operate on strings, no real SMTP connection test.
export async function smtpConfigValidate() {
  return request<boolean>('/api/smtp/validate', {
    method: 'POST',
    headers: getHeaders(),
  });
}

export async function teamInfo(options?: { [key: string]: any }) {
  return request<API.TeamInfo | undefined>('/api/team/info', {
    method: 'GET',
    headers: getHeaders(),
    ...(options || {}),
  });
}

/// 1. Save the SMTP configuration in Team Info.
/// 2. Send a test email to the current user.
export async function updateTeamInfo(options?: { [key: string]: any }) {
  return request<API.CommonMsgResponse>('/api/team/info', {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function userInfo(options?: { [key: string]: any }) {
  return request<API.UserInfo | undefined>('/api/user/info', {
    method: 'GET',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function inviteUser(options?: { [key: string]: any }) {
  return request<boolean>('/api/invite', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function reinviteUser(options?: { [key: string]: any }) {
  return request<boolean>('/api/reinvite', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function verifyReadUser(options?: { [key: string]: any }) {
  return request<API.User>('/api/verify/user', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function verifyUpdateUser(options?: { [key: string]: any }) {
  return request<void>('/api/verify/update', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function getLoginVerificationCode(
  options?: {
    data: {
      email: string;
    },
    [key: string]: any
  },
) {
  return request<{ secret?: string }>('/api/user/verification-code', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function unsubscribeEmail(options?: { [key: string]: any }) {
  return request<void>('/api/user/unsubscribe-email', {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function strategies(options?: { [key: string]: any }) {
  return request<API.StrategyListItem[]>('/api/strategies', {
    method: 'GET',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function strategy(params: { guid: string }, options?: { [key: string]: any }) {
  return request<API.StrategyItem>('/api/strategy', {
    method: 'GET',
    headers: getHeaders(),
    params: params,
    ...(options || {}),
  });
}

export async function updateStrategy(params: { guid: string }, options?: { [key: string]: any }) {
  return request<boolean>('/api/strategy', {
    method: 'PUT',
    headers: getHeaders(),
    params: params,
    ...(options || {}),
  });
}

export async function updateStrategyStatus(params: { guid: string }, options?: { [key: string]: any }) {
  return request<void>('/api/strategy/status', {
    method: 'PUT',
    headers: getHeaders(),
    params: params,
    ...(options || {}),
  });
}

export async function operateStrategy(options?: { [key: string]: any }) {
  return request<void>('/api/strategy/op', {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function assignStrategies(options?: { [key: string]: any }) {
  return request<void>('/api/strategies/assign', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function defaultStrategyOptions(params: { }, options?: { [key: string]: any }) {
  return request<string | undefined>('/api/strategy/options', {
    method: 'GET',
    headers: getHeaders(),
    params: params,
    ...(options || {}),
  });
}

export async function userSessions() {
  return request<API.UserSession[]>('/api/user/sessions', {
    method: 'GET',
    headers: getHeaders(),
  });
}

export async function deleteUserSession(options: { data: { id: string }, [key: string]: any }) {
  return request<void>('/api/user/delete-session', {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function updateSettings(payload: { [key: string]: string | null }, options?: { [key: string]: any }) {
  const headers = getHeaders();
  return request<void>(
    '/api/settings',
    {
      method: 'PUT',
      headers,
      data: payload,
      ...(options || {}),
    },
  );
}

export async function getAllGeoOverrides(options?: { [key: string]: any }) {
  return request<API.GeoOverride[]>('/api/geo', {
    method: 'GET',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function updateGeoOverride(payload: API.GeoOverride[], options?: { [key: string]: any }) {
  return request<void>('/api/geo', {
    method: 'PUT',
    headers: getHeaders(),
    data: payload,
    ...(options || {}),
  });
}

export async function deleteGeoOverrides(ip_or_domains: string[], options?: { [key: string]: any }) {
  return request<void>('/api/geo', {
    method: 'DELETE',
    headers: getHeaders(),
    data: ip_or_domains,
    ...(options || {}),
  });
}

export async function executeCommand(command: string, options?: { [key: string]: any }) {
  return request<string>('/api/execute', {
    method: 'POST',
    headers: getHeaders(),
    data: command,
    ...(options || {}),
  });
}

export async function getSkKey(options?: { [key: string]: any }) {
  return request<API.KeyPairInfo>('/api/keypair', {
    method: 'GET',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function resetSkKey(options?: { [key: string]: any }) {
  return request<API.KeyPairInfo>('/api/keypair', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function updateSkKey(pri_key: string, options?: { [key: string]: any }) {
  return request<void>('/api/keypair', {
    method: 'PUT',
    headers: getHeaders(),
    data: JSON.stringify(pri_key),
    ...(options || {}),
  });
}

export async function getGeofileTimestamp(options?: { [key: string]: any }) {
  return request<string>('/api/geofile', {
    method: 'GET',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function getLoginOptions(options?: { [key: string]: any }) {
  return request<string[]>('/api/login-options', {
    method: 'GET',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function getOidcProviders(options?: { [key: string]: any }) {
  return request<API.OidcProviders>('/api/oidc/settings', {
    method: 'GET',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function getOidcLoginOptions(options?: { [key: string]: any }) {
  return request<API.OidcLoginInfo[]>('/api/oidc/login-options', {
    method: 'GET',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function updateOidcProviders(payload: API.OidcProviders, options?: { [key: string]: any }) {
  return request<void>('/api/oidc/settings', {
    method: 'PUT',
    headers: getHeaders(),
    data: payload,
    ...(options || {}),
  });
}

export async function deleteOidcProvider(op: string, options?: { [key: string]: any }) {
  return request<void>('/api/oidc/settings/' + op, {
    method: 'DELETE',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function reorderOidcProviders(body: string[], options?: { [key: string]: any }) {
  const headers = getHeaders();
  if (!headers['Authorization']) {
    return;
  }
  return request('/api/oidc/settings/reorder', {
    method: 'POST',
    headers,
    data: body,
    ...(options || {}),
  });
}

export async function getSessionExpireTime(options?: { [key: string]: any }) {
  return request<number>('/api/session/exp_time', {
    method: 'GET',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function updateSessionExpireTime(payload: number, options?: { [key: string]: any }) {
  return request<void>('/api/session/exp_time', {
    method: 'PUT',
    headers: getHeaders(),
    data: payload,
    ...(options || {}),
  });
}

export async function getIdChangeSupport(options?: { [key: string]: any }) {
  return request<boolean>('/api/id-change-support', {
    method: 'GET',
    headers: getHeaders(),
    ...(options || {}),
  });
}

// when axios send false, payload length is zero.
// https://github.com/ladjs/superagent/issues/1637
// https://stackoverflow.com/questions/56784849/axios-api-response-json-body-has-true-false-as-string-type-not-boolean
export async function updateIdChangeSupport(payload: boolean, options?: { [key: string]: any }) {
  return request<void>('/api/id-change-support', {
    method: 'PUT',
    headers: getHeaders(),
    data: JSON.stringify(payload),
    ...(options || {}),
  });
}

export async function checkRead(params: {
  file: string;
},
  options?: { [key: string]: any },) {
  return request<boolean>('/api/check/read', {
    method: 'GET',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function userTfaEnable(payload: { tfa_code?: string }, options?: { [key: string]: any },) {
  return request<string>('/api/user/tfa/totp/enable', {
    method: 'POST',
    headers: getHeaders(),
    data: payload,
    ...(options || {}),
  });
}

export async function userTfaVerify(payload: API.TFARequest, options?: { [key: string]: any },) {
  return request<API.TFAResponse>('/api/user/tfa/totp/verify', {
    method: 'POST',
    headers: getHeaders(),
    data: payload,
    ...(options || {}),
  });
}

export async function userTfaGetBackupCodes(payload: API.TFARequest, options?: { [key: string]: any },) {
  return request<string[]>('/api/user/tfa/totp/backup-codes', {
    method: 'GET',
    headers: getHeaders(),
    params: payload,
    ...(options || {}),
  });
}

export async function userTfaDisable(payload: API.TFARequest, options?: { [key: string]: any },) {
  return request('/api/user/tfa/totp/disable', {
    method: 'PUT',
    headers: getHeaders(),
    data: payload,
    ...(options || {}),
  });
}

export async function listCustomClient(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  params = wrapFuzzyQuery(params, ['name', 'app_name']);
  return request<API.CustomClientList>('/api/custom-clients', {
    method: 'GET',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function executeCustomClient(
  guid: string,
  action: string,
  options?: { [key: string]: any },
) {
  return request(`/api/custom-clients/${guid}/${action}`, {
    method: 'POST',
    headers: getHeaders(),
    data: {},
    ...(options || {}),
  }, true);
}

export async function getCustomClient(
  guid: string,
  options?: { [key: string]: any },
) {
  const headers = getHeaders();
  if (!headers['Authorization']) {
    return;
  }

  return request<API.CustomClient>(`/api/custom-clients/${guid}`, {
    method: 'GET',
    headers,
    ...(options || {}),
  });
}

export async function newCustomClient(
  payload: API.NewCustomClient,
  options?: { [key: string]: any },
) {
  const headers = getHeaders();
  if (!headers['Authorization']) {
    return;
  }

  return request('/api/custom-clients', {
    method: 'POST',
    headers,
    data: payload,
    ...(options || {}),
  });
}

export async function updateCustomClient(
  guid: string,
  payload: API.UpdateCustomClient,
  options?: { [key: string]: any },
) {
  const headers = getHeaders();
  if (!headers['Authorization']) {
    return;
  }

  return request(`/api/custom-clients/${guid}`, {
    method: 'PATCH',
    headers,
    data: payload,
    ...(options || {}),
  });
}

export async function deleteCustomClient(
  guid: string,
  options?: { [key: string]: any },
) {
  const headers = getHeaders();
  if (!headers['Authorization']) {
    return;
  }

  return request(`/api/custom-clients/${guid}`, {
    method: 'DELETE',
    headers,
    ...(options || {}),
  });
}

export async function sharedAbProfiles(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  params = wrapFuzzyQuery(params, ['name']);
  return request<API.SharedAbProfileList>('/api/ab/shared/profiles', {
    method: 'POST',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function getSharedAbProfileByGuid(
  ab_guid:string
) {
  return request<API.SharedAbProfile>(`/api/ab/shared/profile/${ab_guid}`, {
    method: 'POST',
    headers: getHeaders(),
  });
}


export async function addSharedAb(options?: { [key: string]: any }) {
  return request('/api/ab/shared/add', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function updateSharedAbProfile(options?: { [key: string]: any }) {
  return request<API.SharedAbProfile>('/api/ab/shared/update/profile', {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function deleteSharedAbArray(options?: { [key: string]: any }) {
  return request('/api/ab/shared', {
    method: 'DELETE',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function getAbPeers(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  params = wrapFuzzyQuery(params, [
    'id',
    'device_name',
    'device_username',
    'alias',
  ]);
  return request<API.AbPeerList>('/api/ab/peers', {
    method: 'GET',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function getAbTags(
  ab_guid: string,
) {
  return request<API.AbTag[]>(`/api/ab/tags/${ab_guid}`, {
    method: 'POST',
    headers: getHeaders(),
  });
}


export async function abAddPeer(ab_guid: string, options?: { [key: string]: any }) {
  return request(`/api/ab/peer/add/${ab_guid}`, {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function abAddTag(ab_guid: string, options?: { [key: string]: any }) {
  return request(`/api/ab/tag/add/${ab_guid}`, {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function abUpdatePeer(ab_guid: string, options?: { [key: string]: any }) {
  return request(`/api/ab/peer/update/${ab_guid}`, {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function abUpdateTag(ab_guid: string, options?: { [key: string]: any }) {
  return request(`/api/ab/tag/update/${ab_guid}`, {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function deleteAbPeersById(ab_guid: string, options?: { [key: string]: any }) {
  return request<void>(`/api/ab/peer/${ab_guid}`, {
    method: 'DELETE',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function deleteAbPeersByGuid(ab_guid: string, options?: { [key: string]: any }) {
  return request<void>(`/api/ab/peer/guid/${ab_guid}`, {
    method: 'DELETE',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function deleteAbTags(ab_guid: string, options?: { [key: string]: any }) {
  return request<void>(`/api/ab/tag/${ab_guid}`, {
    method: 'DELETE',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function getAbSettings(options?: { [key: string]: any }) {
  return request<API.AbSettings>('/api/ab/settings', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function getPersonalAb(options?: { [key: string]: any }) {
  return request<API.PersonalAb>('/api/ab/personal', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function restoreAbPeers(ab_guid: string, options?: { [key: string]: any }) {
  return request<void>(`/api/ab/bin/restore/${ab_guid}`, {
    method: 'PUT',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function deleteRecycledAbPeersById(ab_guid: string, options?: { [key: string]: any }) {
  return request<void>(`/api/ab/bin/${ab_guid}`, {
    method: 'DELETE',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function deleteRecycledAbPeersByGuid(ab_guid: string, options?: { [key: string]: any }) {
  return request<void>(`/api/ab/bin/guid/${ab_guid}`, {
    method: 'DELETE',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function emptyRecycleBin(ab_guid: string, options?: { [key: string]: any }) {
  return request<void>(`/api/ab/bin/empty/${ab_guid}`, {
    method: 'DELETE',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function getAbRulesInPage(
  params: {
    current?: number;
    pageSize?: number;
    ab: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.AbRuleList>('/api/ab/rules', {
    method: 'POST',
    headers: getHeaders(),
    params,
    ...(options || {}),
  });
}

export async function addAbRule(options?: { [key: string]: any }) {
  return request<Object>('/api/ab/rule', {
    method: 'POST',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function updateAbRule(options?: { [key: string]: any }) {
  return request<Object>('/api/ab/rule', {
    method: 'PATCH',
    headers: getHeaders(),
    ...(options || {}),
  });
}

export async function deleteAbRules( options?: { [key: string]: any }) {
  return request<void>('/api/ab/rules', {
    method: 'DELETE',
    headers: getHeaders(),
    ...(options || {}),
  });
}
