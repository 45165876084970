import { Button, Checkbox, message, Divider, Badge, Tooltip, AutoComplete, Input } from 'antd';
import { WindowsFilled, AndroidFilled, AppleFilled, QqCircleFilled, SearchOutlined } from '@ant-design/icons';
import React, { useState, useRef, Fragment, useEffect } from 'react';
import { FooterToolbar } from '@ant-design/pro-layout';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormTextArea, ProFormText, ProForm } from '@ant-design/pro-form';
import { devices, updateDevice, enableDevices, delPeer, names as fetchNames } from '@/services/api';
import { API } from '@/services/typings';
import { useModel } from '@umijs/max';
import { t, renderDetails, getTablePaginationConfig } from '../../global';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { DelConfirmForm } from '../UserList/index';

const hideUsenameKey = "hideUsernameOnDeviceColumn";

const DeviceTip: React.FC<{ actionRef: any }> = (props) => {
  const [hideUsername, setHideUsername] = useState(false);

  useEffect(() => {
    const storedHideUsername = localStorage.getItem(hideUsenameKey) === 'true';
    setHideUsername(storedHideUsername);
  }, []);

  const handleCheckboxChange = (e: any) => {
    const checked = e.target.checked;
    setHideUsername(checked);
    localStorage.setItem(hideUsenameKey, checked.toString());
    props.actionRef?.current?.reload();
  };

  return <div>
    <div style={{ margin: 8 }}>
      <Checkbox
        checked={hideUsername}
        onChange={handleCheckboxChange}
        style={{color: 'white'}}
      > Hide username</Checkbox>
    </div>
    {!hideUsername && <span>{t('username@device_name')}</span>}
  </div>
};

const handleUpdate = async (fields: API.DeviceListItem, old: API.DeviceListItem) => {
  let data = { guid: old.guid } as any;
  data['id'] = old.id;
  if ((fields.note || '') != (old.note || '')) {
    data['note'] = fields.note;
  }
  if ((fields.info?.device_name || '') != (old.info?.device_name || '')) {
    data['device_name'] = fields.info?.device_name;
  }
  if ((fields.user_name || '') != (old.user_name || '')) {
    data['user_name'] = fields.user_name;
  }
  if (Object.keys(data).length == 2) return;
  const hide = message.loading(t('Updating'));
  try {
    await updateDevice({ data });
    hide();

    message.success(t('Update is successful'));
    return true;
  } catch (error) {
    hide();
    message.error(
      typeof error == 'string' ? (error as string) : t('Update failed, please try again!'),
    );
    return false;
  }
};

export const handleEnable = async (rows: API.DeviceListItem[], disable: boolean) => {
  const hide = message.loading(t('Updating'));
  try {
    await enableDevices({
      data: {
        rows: rows.map((x) => x.guid),
        disable,
      },
    });
    hide();

    message.success(t('Update is successful'));
    return true;
  } catch (error) {
    hide();
    message.error(
      typeof error == 'string' ? (error as string) : t('Enable/disable failed, please try again!'),
    );
    return false;
  }
};

const DevicesTable: React.FC = () => {

  const [updateModalVisible, handleUpdateModalVisible] = useState<boolean>(false);
  const [delConfirmModalVisible, handleDelConfirmModalVisible] = useState<boolean>(false);

  const [showDetail, setShowDetail] = useState<boolean>(false);

  const actionRef = useRef<ActionType>();
  const [currentRow, setCurrentRow] = useState<API.DeviceListItem>();
  const [selectedRowsState, setSelectedRows] = useState<API.DeviceListItem[]>([]);
  const { initialState } = useModel('@@initialState');
  const is_admin = initialState?.user?.is_admin;

  useEffect(() => {
    actionRef.current?.reload();
  }, []);

  const columns = deviceColumns({
    actionRef,
    is_admin,
    onIdClick: (entity: API.DeviceListItem) => {
      setCurrentRow(entity);
      setShowDetail(true);
    },
    extraColumns: [
      {
        title: t('Action', true),
        search: false,
        hideInTable: !is_admin,
        hideInDescriptions: true,
        width: '15%',
        render: (text, record) => (
          <Fragment>
            {is_admin ? <Fragment>
              <a onClick={() => {
                setCurrentRow(record);
                handleUpdateModalVisible(true);
              }}>
                {t('Edit')}
              </a>
              <Divider type="vertical" />
              <a
                style={isNormal(record) ? { color: "red" } : {}}
                onClick={async () => {
                  setCurrentRow(record);
                  await handleEnable([record], isNormal(record));
                  actionRef.current?.reloadAndRest?.();
                }}
              >
                {t(isNormal(record) ? 'Disable' : 'Enable')}
              </a>
              {!isNormal(record) && !record.is_online && <>
                <Divider type="vertical" />
                <a
                  style={{ color: "red" }}
                  onClick={async () => {
                    setCurrentRow(record);
                    handleDelConfirmModalVisible(true);
                  }}
                >
                  {t('Delete')}
                </a>
              </>}
            </Fragment> : null}
          </Fragment>
        ),
      },
    ],
  })

  const updateForm = (
    visible: boolean,
    handleModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
    actionRef: React.MutableRefObject<ActionType | undefined>,
    initialValues: API.DeviceListItem | {},
  ) => {
    if (!visible) return null;
    return (
      <ModalForm
        title={t('Edit')}
        visible={visible}
        width="400px"
        initialValues={initialValues}
        onVisibleChange={handleModalVisible}
        onFinish={async (value) => {
          const success = await handleUpdate(
            value as API.DeviceListItem,
            initialValues as API.DeviceListItem,
          );
          if (success) {
            handleModalVisible(false);
            if (actionRef.current) {
              actionRef.current.reload();
            }
          }
        }}
      >
        <NameEditor table="user" field="user_name" label="User" />
        <ProFormText
          fieldProps={{ autoComplete: 'off' }}
          rules={[
            {
              whitespace: true,
              required: true,
            },
            {
              max: 300,
            },
          ]}
          width="md"
          name={["info", "device_name"]}
          label={t('Device Name', true)}
        />
        <ProFormTextArea
          fieldProps={{ autoComplete: 'off' }}
          width="md"
          name="note"
          rules={[
            {
              max: 300,
            },
          ]}
          label={t('Note', true)}
        />
      </ModalForm>
    );
  };

  return (
    <div>
      <ProTable<API.DeviceListItem, API.PageParams>
        headerTitle={t('Device List')}
        actionRef={actionRef}
        columnsState={{ persistenceType: 'localStorage', persistenceKey: 'devicelist_columns_state' }}
        rowKey="id"
        search={{
          labelWidth: 120,
        }}
        request={devices}
        columns={columns}
        rowSelection={
          is_admin
            ? {
              onChange: (_, selectedRows) => {
                setSelectedRows(selectedRows);
              },
            }
            : undefined
        }
        pagination={getTablePaginationConfig("device_list")}
      />
      {selectedRowsState?.length > 0 && (
        <FooterToolbar
          extra={
            <div>
              {t('Chosen')} <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>{' '}
              {t('Items')}
            </div>
          }
        >
          {[true, false].map((v) => (
            <Button
              onClick={async () => {
                await handleEnable(selectedRowsState, !v);
                setSelectedRows([]);
                actionRef.current?.reloadAndRest?.();
              }}
            >
              {v ? t('Enable') : t('Disable')}
            </Button>
          ))}
        </FooterToolbar>
      )}
      {updateForm(updateModalVisible, handleUpdateModalVisible, actionRef, currentRow || {})}
      {renderDetails(
        showDetail,
        currentRow?.guid,
        currentRow,
        columns.slice(0, columns.length - 1).filter((x) => x.title),
        setCurrentRow,
        setShowDetail,
      )}
      {delConfirmModalVisible && <DelConfirmForm visible={delConfirmModalVisible} handleModalVisible={handleDelConfirmModalVisible} actionRef={actionRef} initialValues={currentRow || {}} del={delPeer} />}
    </div>
  );
};

function isNormal(r: API.DeviceListItem) {
  return r.status == 1;
}

export const NameEditor = (props: { table: string, field: string, label: string, required?: boolean }) => {
  const [names, setNames] = useState<{ value: string }[] | undefined>(undefined);
  const fetch = async (pattern: string) => {
    const g = await fetchNames({ table: props.table, limit: 30, pattern });
    setNames((g || []).map((x) => ({ value: x })));
  };
  const handleSearch = (value: string) => {
    fetch(value + '%');
  };
  if (names == undefined) fetch('%');
  return (
    <ProForm.Item
      rules={[
        {
          whitespace: true,
          required: props.required,
        },
      ]}
      name={props.field}
      label={t(props.label, true)}
    >
      <AutoComplete allowClear={true} options={names} style={{ width: 328 }} onSearch={handleSearch} >
        <Input suffix={<SearchOutlined />} placeholder={t("Search", true) as string} />
      </AutoComplete>
    </ProForm.Item>
  );
};


export const deviceColumns = (props: { actionRef: any, is_select?: boolean, is_admin?: boolean, onIdClick?: ((entity: API.DeviceListItem) => void), extraColumns?: ProColumns<API.DeviceListItem>[] }) => {
  const columns: ProColumns<API.DeviceListItem>[] = [
    {
      title: t('ID', true), // plain=true is necessary, otherwise will crash
      dataIndex: 'id',
      width: '15%',
      render: (dom, entity) => {
        let os = (entity.info?.os || '').split(' / ');
        return (<>
          <Tooltip title={t(entity.is_online ? 'Online' : 'Offline')}>
            <Badge status={entity.is_online ? 'success' : 'error'} />&nbsp;&nbsp;
          </Tooltip>
          {os[0] && <Tooltip title={os[1]}>
            {os[0] == 'windows' && <WindowsFilled />}
            {(entity.info?.os?.toLowerCase().indexOf('linux') || -1) >= 0 && <QqCircleFilled />}
            {os[0] == 'android' && <AndroidFilled />}
            {os[0] == 'macos' && <AppleFilled />}
            {os[0] == 'ios' && <AppleFilled />}
            &nbsp;&nbsp;
          </Tooltip>}
          {props.onIdClick ? <a
            onClick={() => props.onIdClick?.(entity)}
          >
            {dom}
          </a> : <span>{dom}</span>}</>

        );
      },
    },
    {
      title: t('Device Name', true),
      dataIndex: 'device_name',
      hideInTable: true,
      hideInDescriptions: true,
    },
    {
      title: t('Device Username', true),
      dataIndex: 'device_username',
      hideInTable: true,
      hideInDescriptions: true,
    },
    {
      title: t('Device', true),
      tooltip: <DeviceTip actionRef={props.actionRef} />,
      hideInSearch: true,
      render: (dom, entity) => {
        const hideUsername = localStorage.getItem(hideUsenameKey) === 'true';
        if (entity.info?.username && entity.info?.device_name && !hideUsername) {
          return entity.info?.username + '@' + entity.info?.device_name;
        } else {
          return entity.info?.device_name;
        }
      }
    },
    {
      title: t('User', true),
      dataIndex: 'user_name',
      hideInSearch: !props.is_admin,
      hideInTable: !props.is_admin,
    },
    {
      title: t('Group', true),
      dataIndex: 'group_name',
      hideInTable: !props.is_admin,
      hideInSearch: !props.is_admin,
      hideInSetting: !props.is_admin,
      hideInDescriptions: !props.is_admin,
    },
    {
      title: t('Status', true),
      dataIndex: 'status',
      hideInSearch: props.is_select,
      valueEnum: {
        1: {
          text: t('Normal'),
          status: 'Default',
        },
        0: {
          text: t('Disabled'),
          status: 'Disabled',
        },
      },
      render: (text, record) => {
        if (!isNormal(record)) {
          return (
            <Tooltip title={t('Disabled')}>
              <CloseCircleOutlined style={{ color: '#f5222d' }} />
            </Tooltip>
          );
          return <Badge status="default" text={t('Normal')} />;
        } else {
          return (
            <Tooltip title={t('Normal')}>
              <CheckCircleOutlined style={{ color: '#52c41a' }} />
            </Tooltip>
          );
        }
      },
    },
    {
      title: t('Strategy', true),
      dataIndex: 'strategy_name',
      hideInSearch: true,
      tooltip: `${t('strategy_tooltip', true, '\'-\' is the default strategy, the strategy following \'/\' is the user\'s strategy which will be applied to the device when its strategy is empty.')}`,
      hideInTable: !props.is_admin,
      hideInSetting: !props.is_admin,
      hideInDescriptions: !props.is_admin,
    },
    {
      title: t('Device Strategy', true),
      dataIndex: 'strategy_name',
      hideInDescriptions: true,
      hideInTable: true,
    },
    {
      title: t('User Strategy', true),
      dataIndex: 'user_strategy_name',
      hideInDescriptions: true,
      hideInTable: true,
    },
    {
      title: t('Info', true),
      dataIndex: 'info',
      valueType: 'textarea',
      search: false,
      ellipsis: true,
      render: (text, record) => {
        let txt = '';
        try {
          let info = record.info;
          if (!info) return '';
          if (info.ip) {
            txt += 'ip: ' + info.ip.replace('::ffff:', '') + '; ';
          }
          if (info.version) {
            txt += 'version: ' + info.version + '; ';
          }
          if (info.version) {
            txt += 'cpu: ' + info.cpu + '; ';
          }
          if (info.os) {
            txt += 'mem: ' + info.memory + '; ';
          }
        } catch (e) { }
        return txt;
      },
    },
    {
      title: t('Note', true),
      dataIndex: 'note',
      valueType: 'textarea',
      search: false,
      hideInSearch: true,
      ellipsis: true,
    },
    ...props.extraColumns || [],
  ];

  return columns;
}
export default DevicesTable;
