import { Button, message, Result } from 'antd';
import React, { useRef, useState } from 'react';
import type { ProFormInstance} from '@ant-design/pro-form';
import { ProFormText, StepsForm } from '@ant-design/pro-form';
import { history, SelectLang, useLocation } from '@umijs/max';
import Footer from '@/components/Footer';
import styles from '../Login/index.less';
import ProCard from '@ant-design/pro-card';
import { decode } from 'js-base64';
import { UserPassword } from '@/pages/UserList';
import { useEffect } from 'react';
import { verifyReadUser, verifyUpdateUser } from '@/services/api';
import queryString from 'query-string';
import type { API } from '@/services/typings';
import { fallbackEnUs } from '@/global';

interface Claims {
  user_guid: string,
  exp: number,
}

const Invalid = () => <Result
  status="error"
  title="Invalid params"
  subTitle="Please check your url and try again." />;

const Expired = () => <Result
  status="error"
  title="Expired Token"
  subTitle="Please re-register" />;

export function checkToken(token: string | undefined): Claims | undefined {
  if (!token) {
    return undefined;
  }

  const jwt = decode(token);

  const jwt_arr = jwt.split(".");
  if (jwt_arr.length != 3) {
    return undefined;
  }

  try {
    const claims = JSON.parse(decode(jwt_arr[1])) as Claims;
    return claims;
  } catch (error) {
    console.error("JSON parse failed.", error);
    return undefined;
  }
}

export function isExpired(claims: Claims): boolean {
  const current = Date.parse(Date());
  return current > claims.exp * 1000;
}

const Varify: React.FC = () => {
  const formRef = useRef<ProFormInstance>();
  const [current, updateCurrent] = useState(0);

  const location = useLocation();
  const query = queryString.parse(location.search);
  const { token } = query as { token: string | undefined };

  useEffect(() => {
    if (token) {
      const data: API.VerifyUserPayload = {
        token: decode(token),
        new_password: ''
      }
      const func = async () => {
        const v = await verifyReadUser({ data });
        formRef.current?.setFieldsValue(v);
      };
      func().catch(message.error);
    }
  }, [token]);

  const claims = checkToken(token);
  if (!claims) {
    return (<Invalid />);
  }

  if (isExpired(claims)) {
    return (<Expired />);
  }

  return fallbackEnUs(
    <div className={styles.container}>
      <div className={styles.lang} data-lang>
        {SelectLang && <SelectLang />}
      </div>
      <div className={styles.content}>
        <ProCard ghost>
          <StepsForm
            formRef={formRef}
            current={current}
            submitter={{
              render: (props) => {
                if (props.step === 0) {
                  return (
                    <Button type="primary" onClick={() => props.onSubmit?.()}>
                      Next {'>'}
                    </Button>
                  );
                }
                return undefined;
              }
            }}
          >
            <StepsForm.StepForm
              name="register"
              title="Register"
              stepProps={{
                description: 'Register',
              }}
              onFinish={async (v) => {
                const hide = message.loading("Updating");
                const data: API.VerifyUserPayload = {
                  token: decode(token!),
                  new_password: v["password"]
                };
                try {
                  await verifyUpdateUser({ data });
                  message.success("User registration is successful!");
                  updateCurrent(1);
                  return true;
                } catch (error) {
                  message.error(error as any)
                  return false;
                } finally {
                  hide();
                }
              }}
            >
              <ProFormText readonly width="md" label="User Name" name={'name'} />
              <ProFormText readonly label="Email" width="md" name={'email'} />
              <UserPassword required />
            </StepsForm.StepForm>
            <StepsForm.StepForm
              name="success"
              title="Success"
              stepProps={{
                description: 'Success',
              }}
            >
              <Result
                icon={<img width={'60px'} alt="logo" src="./logo.svg" />}
                title="Great! Registered user successfully!"
                extra={<Button onClick={() => {
                  history.push("/user/login");
                  window.location.reload();
                }} type="primary">Login</Button>}
              />
            </StepsForm.StepForm>
          </StepsForm>
        </ProCard>
      </div>
      <Footer />
    </div>
  );
};

export default Varify;
