// LicenseModal.tsx
import { useState, useEffect, useRef } from 'react';
import { useAntdConfig, useIntl, useModel } from '@umijs/max';
import { Form, Input, Modal, message, Alert, ConfigProvider, theme } from 'antd';
import { updateSettings, initialState as queryInitialState } from '@/services/api';
import { t } from '@/global';
import { checkHasAlgorithm } from '../RightContent/ThemeSwitch';
const { darkAlgorithm } = theme;

type LicenseModalProps = {
  once: boolean;
  trigger?: number;
};

export const LicenseModal: React.FC<LicenseModalProps> = (props) => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const [isLicenseModalVisible, setIsLicenseModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const intl = useIntl();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const defaultValue = initialState?.settings?.find((entry) => entry.key === 'LICENSE')?.value;

  const plainSetting = (text: string, defaultMessage = '') => t(`pages.Settings.License.${text}`, true, defaultMessage || text, intl) as string;

  useEffect(
    () => {
      const status_text = initialState?.license?.status_text;
      if (props.once) {
        if (status_text && status_text.indexOf('not set') >= 0) {
          setIsLicenseModalVisible(true);
        }
      } else {
        setIsLicenseModalVisible((props.trigger || 0) > 0);
      }
    },
    props.once ? [] : [props.trigger],
  );

  const onConfirm = async () => {
    try {
      try {
        await form.validateFields();
      } catch (e) {
        return;
      }
      const values = form.getFieldsValue(['LICENSE']);
      if (!values) {
        message.error(plainSetting('licenseCannotBeEmpty', 'License cannot be empty.'));
        return;
      }
      values.LICENSE = values.LICENSE.trim();
      setConfirmLoading(true);
      var failed = false;
      try {
        await updateSettings(values);
      } catch (e) {
        message.error('Failed to update license: ' + e, 6);
        failed = true;
      }
      const current = await queryInitialState();
      if (current) {
        await setInitialState((s) => ({
          ...s,
          ...current,
        }));
        if (current.license?.status_text) {
          if (!failed) message.error(current.license?.status_text);
        } else {
          if (!failed) message.success('Update license successfully.');
          timerRef.current = setTimeout(() => {
            setIsLicenseModalVisible(false);
          }, 300);
        }
      }
    } catch (e) {
      message.error('Failed to update license: ' + e, 6);
    }
    setConfirmLoading(false);
  };

  return (isLicenseModalVisible &&
    <Modal
      title={t('Enter License')}
      open={isLicenseModalVisible}
      confirmLoading={confirmLoading}
      onCancel={() => {
        if (timerRef.current) clearTimeout(timerRef.current);
        setIsLicenseModalVisible(false);
      }}
      onOk={onConfirm}
      maskClosable={false}
    >
      <Form form={form} initialValues={{ LICENSE: defaultValue }}>
        <Form.Item
          label={t('License')}
          name="LICENSE"
          rules={[
            {
              required: true,
              whitespace: true,
              message: plainSetting('licenseCannotBeEmpty', 'License cannot be empty.'),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const LicenseAlert: React.FC = () => {
  const { initialState } = useModel('@@initialState');

  const intl = useIntl();
  const plain = (text: string, defaultMessage = '') => t(`component.LicenseAlert.${text}`, true, defaultMessage || text, intl) as string;
  const antdConfig = useAntdConfig();
  const isDark = checkHasAlgorithm(antdConfig, darkAlgorithm);

  // Function to detect URLs and wrap them in <a> tags
  const linkify = (inputText: string) => {
    const regex = /(\bhttps:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    return inputText.split(regex).map((part, index) => {
      if (part.match(regex)) {
        return <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
      }
      return part;
    });
  };
  const getAlert = (type: 'success' | 'info' | 'warning' | 'error', message: string) => {
    let newMsg: any = message;
    try {
      newMsg = linkify(message);
    } catch (e) {}

    return <ConfigProvider
      theme={{
        token: {
          colorErrorBg: isDark ? '#7c2826' : '#FDD3D2',
        },
      }}
    >
      <Alert message={newMsg} type={type} banner showIcon />
    </ConfigProvider>;
  };
  let status_text = initialState?.license?.status_text;
  const expiry = initialState?.license?.expiry;
  let alertType: "success" | "info" | "warning" | "error" = 'error';
  if (!status_text && expiry) {
    const date1 = new Date();
    const date2 = new Date(expiry * 1000);
    const ms = date2.getTime() - date1.getTime();
    if (ms > 0 && ms < 1000 * 3600 * 24 * 7) {
      status_text = plain('licenseExpiringSoon', 'License is expiring soon, please follow this link https://rustdesk.com/docs/en/self-host/rustdesk-server-pro/license/#renewupgrade-license to renew or upgrade your license.');
      alertType = 'warning';
    }
  }
  if (status_text) {
    return getAlert(alertType, plain(status_text));
  }
  return <></>;
};
