import { Button } from 'antd';
import React from 'react';
import { history } from '@umijs/max';
import styles from './40X.less';

const NoAccessPage: React.FC = () => (
  <div className={styles.container}>
    <h2>403</h2>
    <div>Sorry, you are not authorized to access this page.</div>
    <div>
      <Button type="primary" onClick={() => history.push('/')}> Back Home </Button>
    </div>
  </div>
);

export default NoAccessPage;
