export default {
  'pages.layouts.userLayout.title': 'طراحی مورچه تأثیرگذارترین مشخصات طراحی وب در منطقه Xihu است',
  'pages.login.accountLogin.tab': 'ورود به حساب کاربری',
  'pages.login.accountLogin.errorMessage': 'نام کاربری / رمزعبور نادرست (مدیر / ant.design)',
  'pages.login.username.placeholder': 'نام کاربری: مدیر یا کاربر',
  'pages.login.username.required': 'لطفا نام کاربری خود را وارد کنید!',
  'pages.login.password.placeholder': 'رمز عبور: ant.design',
  'pages.login.password.required': 'لطفاً رمز ورود خود را وارد کنید!',
  'pages.login.phoneLogin.tab': 'ورود به سیستم تلفن',
  'pages.login.phoneLogin.errorMessage': 'خطای کد تأیید',
  'pages.login.phoneNumber.placeholder': 'شماره تلفن',
  'pages.login.phoneNumber.required': 'لطفاً شماره تلفن خود را وارد کنید!',
  'pages.login.phoneNumber.invalid': 'شماره تلفن نامعتبر است!',
  'pages.login.captcha.placeholder': 'کد تایید',
  'pages.login.captcha.required': 'لطفا کد تأیید را وارد کنید!',
  'pages.login.phoneLogin.getVerificationCode': 'دریافت کد',
  'pages.getCaptchaSecondText': 'ثانیه',
  'pages.login.rememberMe': 'مرا به خاطر بسپار',
  'pages.login.forgotPassword': 'رمز عبور را فراموش کرده اید ?',
  'pages.login.submit': 'ارسال',
  'pages.login.loginWith': 'وارد شوید با :',
  'pages.login.registerAccount': 'ثبت نام',
  'pages.welcome.advancedComponent': 'مولفه پیشرفته',
  'pages.welcome.link': 'خوش آمدید',
  'pages.welcome.advancedLayout': 'چیدمان پیشرفته',
  'pages.welcome.alertMessage': 'اجزای سنگین تر سریعتر و قوی تر آزاد شده اند.',
  'pages.admin.subPage.title': 'این صفحه فقط توسط مدیر قابل مشاهده است',
  'pages.admin.subPage.alertMessage':
    'رابط کاربری Umi اکنون منتشر شده است ، برای شروع تجربه استفاده از npm run ui خوش آمدید.',
};
