export default {
  'app.setting.pagestyle': 'পৃষ্ঠা স্টাইল সেটিং',
  'app.setting.pagestyle.dark': 'ডার্ক স্টাইল',
  'app.setting.pagestyle.light': 'লাইট স্টাইল',
  'app.setting.content-width': 'সামগ্রীর প্রস্থ',
  'app.setting.content-width.fixed': 'স্থির',
  'app.setting.content-width.fluid': 'প্রবাহী',
  'app.setting.themecolor': 'থিম রঙ',
  'app.setting.themecolor.dust': 'ডাস্ট রেড',
  'app.setting.themecolor.volcano': 'আগ্নেয়গিরি',
  'app.setting.themecolor.sunset': 'সানসেট কমলা',
  'app.setting.themecolor.cyan': 'সবুজাভ নীল',
  'app.setting.themecolor.green': 'পোলার সবুজ',
  'app.setting.themecolor.daybreak': 'দিবস ব্রেক ব্লু (ডিফল্ট)',
  'app.setting.themecolor.geekblue': 'গিক আঠালো',
  'app.setting.themecolor.purple': 'গোল্ডেন বেগুনি',
  'app.setting.navigationmode': 'নেভিগেশন মোড',
  'app.setting.sidemenu': 'সাইড মেনু লেআউট',
  'app.setting.topmenu': 'টপ মেনু লেআউট',
  'app.setting.fixedheader': 'স্থির হেডার',
  'app.setting.fixedsidebar': 'স্থির সাইডবার',
  'app.setting.fixedsidebar.hint': 'সাইড মেনু বিন্যাসে কাজ করে',
  'app.setting.hideheader': 'স্ক্রোল করার সময় হেডার লুকানো',
  'app.setting.hideheader.hint': 'লুকানো হেডার সক্ষম থাকলে কাজ করে',
  'app.setting.othersettings': 'অন্যান্য সেটিংস্',
  'app.setting.weakmode': 'দুর্বল মোড',
  'app.setting.copy': 'সেটিং কপি করুন',
  'app.setting.copyinfo': 'সাফল্যের অনুলিপি করুন - প্রতিস্থাপন করুন: src/models/setting.js',
  'app.setting.production.hint':
    'কেবল বিকাশের পরিবেশে প্যানেল শো সেট করা হচ্ছে, দয়া করে ম্যানুয়ালি সংশোধন করুন',
};
