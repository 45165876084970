// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import { AntdConfigContext, AntdConfigContextSetter } from './context';

export function useAntdConfig() {
  return React.useContext(AntdConfigContext);
}

export function useAntdConfigSetter() {
  return React.useContext(AntdConfigContextSetter);
}
