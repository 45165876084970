import { Button } from 'antd';
import React from 'react';
import { history } from '@umijs/max';
import styles from './40X.less';

const NoFoundPage: React.FC = () => (
  <div className={styles.container}>
    <h2>404</h2>
    <div>Sorry, the page you visited does not exist.</div>
    <div>
      <Button type="primary" onClick={() => history.push('/')}> Back Home </Button>
    </div>
  </div>
);

export default NoFoundPage;
