export default {
  'pages.layouts.userLayout.title':
    'Ant Design adalah spesifikasi desain Web yang paling berpengaruh di Kabupaten Xihu',
  'pages.login.accountLogin.tab': 'Login dengan akun',
  'pages.login.accountLogin.errorMessage': 'Nama pengguna dan kata sandi salah(admin/ant.design)',
  'pages.login.username.placeholder': 'nama pengguna: admin atau user',
  'pages.login.username.required': 'Nama pengguna harus diisi!',
  'pages.login.password.placeholder': 'kata sandi: ant.design',
  'pages.login.password.required': 'Kata sandi harus diisi!',
  'pages.login.phoneLogin.tab': 'Login dengan ponsel',
  'pages.login.phoneLogin.errorMessage': 'Kesalahan kode verifikasi',
  'pages.login.phoneNumber.placeholder': 'masukkan nomor telepon',
  'pages.login.phoneNumber.required': 'Nomor ponsel harus diisi!',
  'pages.login.phoneNumber.invalid': 'Nomor ponsel tidak valid!',
  'pages.login.captcha.placeholder': 'kode verifikasi',
  'pages.login.captcha.required': 'Kode verifikasi diperlukan!',
  'pages.login.phoneLogin.getVerificationCode': 'Dapatkan kode',
  'pages.getCaptchaSecondText': 'detik tersisa',
  'pages.login.rememberMe': 'Ingat saya',
  'pages.login.forgotPassword': 'Lupa Kata Sandi?',
  'pages.login.submit': 'Masuk',
  'pages.login.loginWith': 'Masuk dengan :',
  'pages.login.registerAccount': 'Daftar Akun',
  'pages.welcome.advancedComponent': 'Formulir Lanjutan',
  'pages.welcome.link': 'Selamat datang',
  'pages.welcome.advancedLayout': 'Tata letak Lanjutan',
  'pages.welcome.alertMessage':
    'Komponen heavy-duty yang lebih cepat dan lebih kuat telah dirilis.',
  'pages.admin.subPage.title': 'Halaman ini hanya dapat dilihat oleh admin',
  'pages.admin.subPage.alertMessage':
    'umi ui telah dirilis, silahkan gunakan npm run ui untuk memulai pengalaman.',
};
