import { GithubOutlined } from '@ant-design/icons';
import { DefaultFooter } from '@ant-design/pro-layout';
import { t } from '../../global';

const Footer: React.FC = () => {
  const defaultMessage = t('app.copyright.produced', true, 'Purslane Ltd. Produced');

  const currentYear = new Date().getFullYear();

  return (
    <DefaultFooter
      copyright={`${currentYear} ${defaultMessage}`}
      links={[
        {
          key: 'github',
          title: <GithubOutlined />,
          href: 'https://github.com/rustdesk/rustdesk',
          blankTarget: true,
        },
        {
          key: 'RustDesk',
          title: 'rustdesk.com',
          href: 'https://rustdesk.com',
          blankTarget: true,
        },
      ]}
    />
  );
};

export default Footer;
