const GetOidcSettings = (dark: boolean | undefined) => {
  const githubColor = dark ? '#fff': '#231f20';
  const oktaColor =  dark? '#fff': '#000';
  const auth0Color =  dark? '#fff': '#000';
  return {
    icon: {
        content: '<svg viewBox="0 0 120 120"><path d="M142.554 52.81c0-4.113 1.078-6.374 5.369-11.26 17.207-19.593 57.193-19.593 74.4 0 4.291 4.886 5.37 7.147 5.37 11.26v5.145h-85.14zm71.239-42.863 6.676-6.692 10.462 10.74 25.49-25.453 6.133 6.543-31.536 32.356-17.225-17.494Zm-34.474 3.377c-15.027-5.337-19.348-22.264-8.57-33.575 10.85-11.387 29.85-6.099 34.149 9.503 2.523 9.161-4.38 21.951-12.951 23.995-4.39 1.58-8.73 1.433-12.628.077z" style="fill:#024eff;fill-opacity:1;stroke-width:.999998" transform="translate(-142.554 44.365)"/></svg>',
        size: 20,
    },
    google: {
        icon: {
            content: '<svg viewBox="0 0 48 48" height="26" width="26"><g transform="translate(0,-3)"><path d="m43.611 20.083h-1.611v-.083h-18v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657c-3.572-3.329-8.35-5.382-13.618-5.382-11.045 0-20 8.955-20 20s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z" fill="#ffc107"/><path d="m6.306 14.691 6.571 4.819c1.778-4.402 6.084-7.51 11.123-7.51 3.059 0 5.842 1.154 7.961 3.039l5.657-5.657c-3.572-3.329-8.35-5.382-13.618-5.382-7.682 0-14.344 4.337-17.694 10.691z" fill="#ff3d00"/><path d="m24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238c-2.008 1.521-4.504 2.43-7.219 2.43-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025c3.31 6.477 10.032 10.921 17.805 10.921z" fill="#4caf50"/><path d="m43.611 20.083h-1.611v-.083h-18v8h11.303c-.792 2.237-2.231 4.166-4.087 5.571.001-.001.002-.001.003-.002l6.19 5.238c-.438.398 6.591-4.807 6.591-14.807 0-1.341-.138-2.65-.389-3.917z" fill="#1976d2"/></g></svg>',
            size: 20,
        },
        fields: ['client_id', 'client_secret'],
    },
    github: {
        icon: {
            content: `<svg viewBox="0 0 32 32" width="32" height="32"><path d="m0 0h24v24h-24z" fill="#fff" opacity="0" transform="matrix(-1 0 0 -1 24 24)"/><path d="m12 1a10.89 10.89 0 0 0 -11 10.77 10.79 10.79 0 0 0 7.52 10.23c.55.1.75-.23.75-.52s0-.93 0-1.83c-3.06.65-3.71-1.44-3.71-1.44a2.86 2.86 0 0 0 -1.22-1.58c-1-.66.08-.65.08-.65a2.31 2.31 0 0 1 1.68 1.11 2.37 2.37 0 0 0 3.2.89 2.33 2.33 0 0 1 .7-1.44c-2.44-.27-5-1.19-5-5.32a4.15 4.15 0 0 1 1.11-2.91 3.78 3.78 0 0 1 .11-2.84s.93-.29 3 1.1a10.68 10.68 0 0 1 5.5 0c2.1-1.39 3-1.1 3-1.1a3.78 3.78 0 0 1 .11 2.84 4.15 4.15 0 0 1 1.17 2.89c0 4.14-2.58 5.05-5 5.32a2.5 2.5 0 0 1 .75 2v2.95s.2.63.75.52a10.8 10.8 0 0 0 7.5-10.22 10.89 10.89 0 0 0 -11-10.77" fill="${githubColor}"/></svg>`,
            size: 20,
        },
        fields: ['client_id', 'client_secret'],
    },
    okta: {
        icon: {
            content: `<svg version="1.0" width="70px" height="30px" viewBox="0 0 400 200"><g transform="translate(-20,174) scale(0.1,-0.1)" fill="${oktaColor}" stroke="none"><path d="M627 734 c-4 -4 -7 -165 -7 -359 0 -260 3 -354 12 -363 13 -13 85 -16 112 -6 13 5 16 24 16 100 0 133 7 133 138 1 l105 -107 67 0 c115 0 113 10 -28 154 -67 67 -122 130 -122 139 0 10 37 58 83 108 133 147 133 149 24 149 l-72 0 -79 -86 c-48 -52 -85 -84 -95 -82 -14 3 -17 27 -21 178 l-5 175 -60 3 c-34 2 -64 0 -68 -4z"/><path d="M1184 727 c-3 -8 -4 -127 -2 -264 l3 -250 30 -58 c50 -98 163 -165 260 -153 28 3 30 6 33 47 5 75 -3 91 -46 91 -50 0 -102 29 -124 71 -21 40 -26 177 -6 197 7 7 42 12 85 12 l73 0 0 65 0 65 -72 0 c-40 0 -78 4 -85 8 -7 5 -13 40 -15 92 l-3 85 -63 3 c-47 2 -64 -1 -68 -11z"/><path d="M185 537 c-119 -48 -179 -133 -179 -257 -1 -62 4 -84 26 -125 52 -99 134 -149 243 -148 82 0 128 18 186 70 54 49 81 104 87 179 8 110 -41 205 -135 261 -37 21 -61 27 -122 30 -45 1 -89 -2 -106 -10z m152 -136 c70 -32 96 -117 59 -189 -34 -66 -112 -89 -183 -55 -100 47 -95 194 7 245 44 23 65 22 117 -1z"/><path d="M1713 533 c-51 -18 -122 -83 -147 -137 -45 -96 -27 -218 45 -299 56 -64 117 -91 204 -91 59 0 79 4 128 31 31 17 57 27 57 23 0 -5 15 -18 34 -29 42 -27 116 -37 145 -22 19 11 22 19 19 69 -3 55 -4 56 -38 65 -63 16 -64 19 -70 220 l-5 182 -63 3 c-46 2 -64 -1 -68 -11 -4 -12 -11 -12 -42 -1 -49 17 -147 16 -199 -3z m150 -127 c54 -22 87 -70 87 -124 0 -109 -93 -170 -195 -128 -75 32 -101 144 -48 207 27 32 74 58 106 59 10 0 32 -6 50 -14z"/></g></svg>`,
            size: 38,
        },
        fields: ['client_id', 'client_secret', 'issuer'],
    },
    azure: {
        icon: {
            content: '<svg viewBox="0 0 400 400" width="45px" height="45px"><g transform="translate(10,55)"><path d="m118.431947 187.698037c32.890056-5.8101 60.055363-10.617957 60.367362-10.684121l.567276-.120304-31.051455-36.934731c-17.078287-20.314105-31.05144-37.0135-31.05144-37.109763 0-.182257 32.06325-88.4775168 32.243499-88.791861.060266-.104805 21.880281 37.566853 52.893149 91.318807 29.034814 50.323542 52.972611 91.815483 53.195106 92.204295l.404552.706942-98.684084-.012729-98.6840664-.012677 59.8001014-10.563822zm-118.43195104-11.263314c0-.052002 14.63129504-25.450782 32.51398844-56.441754l32.5139832-56.3472172 37.8912894-31.7984466c20.840208-17.4891418 37.94741-31.81982192 38.015996-31.84667602.068586-.02541214-.205562.66458386-.609219 1.53405261-.403657.86946874-18.91873 40.58235031-41.1446072 88.25084461l-40.4106754 86.6699876-29.3853777.036841c-16.1619541.020284-29.38537774-.005691-29.38537774-.057652z" fill="#0089d6"/></g></svg>',
            size: 38,
        },
        fields: ['client_id', 'client_secret', 'issuer'],
    },
    facebook: {
        icon: {
            content: '<svg viewBox="0 0 1024 1024" widht="56px" height="46px"><g transform="translate(0,160)"><rect fill="#1877f2" height="512" rx="7.5%" width="512"/><path d="m355.6 330 11.4-74h-71v-48c0-20.2 9.9-40 41.7-40h32.3v-63s-29.3-5-57.3-5c-58.5 0-96.7 35.4-96.7 99.6v56.4h-65v74h65v182h80v-182z" fill="#fff"/></g></svg>',
            size: 38,
        },
        fields: ['client_id', 'client_secret'],
    },
    auth0: {
        icon: {
            content: `<svg viewBox="0 0 64 64" width="48" height="48"><g transform="translate(0,10)" fill="${auth0Color}"><path d="m29.307 9.932-3.146-9.932h-20.365l-3.104 9.932c-1.802 5.75.042 12.271 5.089 16.021l8.229 6.047 8.208-6.068c5.005-3.75 6.911-10.25 5.089-16.021l-8.214 6.104 3.12 9.938-8.208-6.13-8.208 6.104 3.141-9.911-8.25-6.063 10.177-.063 3.146-9.891 3.141 9.87z"/></g></svg>`,
            size: 38,
        },
        fields: ['client_id', 'client_secret', 'issuer'],
    },
    gitlab: {
        icon: {
            content: '<svg width="52px" height="52px" preserveAspectRatio="xMidYMid" viewBox="0 0 512 472" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0,60)"><path d="m128.07485 236.074667 47.104-144.9703622h-94.2080005z" fill="#e24329"/><path d="m128.07485 236.074423-47.1040005-144.970362h-66.0150857z" fill="#fc6d26"/><path d="m14.9558857 91.1044267-14.31405713 44.0551623c-1.3056 4.017981.12434286 8.419961 3.54255238 10.903161l123.89059005 90.012039z" fill="#fca326"/><path d="m14.9558857 91.1045486h66.0150857l-28.3708952-87.31428574c-1.4592-4.49340953-7.8153143-4.49219048-9.2745143 0z" fill="#e24329"/><path d="m128.07485 236.074423 47.104-144.970362h66.015085z" fill="#fc6d26"/><path d="m241.193935 91.1044267 14.314057 44.0551623c1.3056 4.017981-.124342 8.419961-3.542552 10.903161l-123.89059 90.012039z" fill="#fca326"/><path d="m241.193935 91.1045486h-66.015085l28.370895-87.31428574c1.4592-4.49340953 7.815314-4.49219048 9.274514 0z" fill="#e24329"/></g></svg>',
            size: 38,
        },
        fields: ['client_id', 'client_secret'],
    },
    // apple: {
    //     icon: {
    //         src: '/icons/Apple.svg',
    //         size: 38,
    //     },
    //     fields: ['client_id', 'client_secret', 'issuer'],
    // }
  }
}

export default GetOidcSettings;
