import React, { useEffect, useRef, useState } from 'react';
import type { FormInstance, InputRef } from 'antd';
import { Button, Input, Tooltip, message } from 'antd';
import { LockOutlined, QuestionCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { userTfaEnable } from '@/services/api';
import { useIntl } from '@umijs/max';
import styles from './tfa.less';
import { t } from '@/global';
import EnableTfaForm from './EnableTfaForm';
import ProForm from '@ant-design/pro-form';
import type { API } from '@/services/typings';
import { SettingsActionTooltipIcon } from '../userSettings';

type TfaComponentProps = {
    currentUser?: API.User;
    handleSwitchSecurity: () => void;
    refreshInitialState: () => Promise<void>;
};

type EnableTfaActionProps = {
    isButton?: boolean;
    plain: (text: string, defaultMessage?: string) => string;
    className?: string;
    refreshInitialState: () => Promise<void>;
}

export const IsTfaVerificationError = (error: string) => error.indexOf('Can\'t verify the code') != -1;

export const TfaButtonTooltipIcon = (title: string) => <Tooltip title={title}>
    <QuestionCircleOutlined className={styles.tooltipIcon} />
</Tooltip>;

export const EnableTfaAction: React.FC<EnableTfaActionProps> = ({ isButton, plain, className, refreshInitialState }) => {
    const [enableModalOpen, setEnableModalOpen] = useState<boolean>(false);
    const [otpauth, setOtpauth] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleEnableTfa = async () => {
        try {
            setLoading(true);
            const otpauth_url = await userTfaEnable({});
            setOtpauth(otpauth_url);
            setEnableModalOpen(true);
            setLoading(false);
            return true;
        } catch (error: any) {
            message.error(error);
            return false;
        }
    };

    const handleModalOpen = (open: boolean, tfa_updated: boolean) => {
        setEnableModalOpen(open);
        if (tfa_updated) {
            refreshInitialState();
        }
    };

    return <>
        {
            isButton ? <div>
                <Button type='primary' onClick={handleEnableTfa} className={className} loading={loading}>
                    {plain('Enable 2FA')}
                </Button>
                {TfaButtonTooltipIcon(plain('Enable 2 Factor Authentication.'))}
            </div> : <div>
                <a onClick={loading ? undefined : handleEnableTfa} className={className}>
                    {loading && <span style={{ marginRight: '5px' }}><LoadingOutlined /></span>}
                    {plain('Enable 2FA')}
                </a>
                {SettingsActionTooltipIcon(plain('Enable 2 Factor Authentication.'))}
            </div>
        }
        <EnableTfaForm
            otpauth={otpauth}
            modalOpen={enableModalOpen}
            handleModalOpen={handleModalOpen}
            plain={plain}
        />
    </>;
}

type RenewTfaButtonProps = {
    plain: (text: string, defaultMessage?: string) => string;
}

const RenewTfaButton: React.FC<RenewTfaButtonProps> = ({ plain }) => {
    const [renewModalOpen, setRenewModalOpen] = useState<boolean>(false);

    const handleRenewTfa = async () => setRenewModalOpen(true);

    return <>
        <div
            className={styles.expired}
            onClick={handleRenewTfa}
        >
            <LockOutlined />
            <span style={{ marginLeft: '6px' }}>{plain('Renew 2FA')}</span>
        </div>
        <EnableTfaForm
            modalOpen={renewModalOpen}
            handleModalOpen={setRenewModalOpen}
            plain={plain}
        />
    </>;
}

export const TfaComponent: React.FC<TfaComponentProps> = ({ currentUser, handleSwitchSecurity, refreshInitialState }) => {
    const statusTfa = currentUser?.status_tfa;

    const intl = useIntl();
    const plain = (text: string, defaultMessage = '') => t(`pages.account.tfaComponent.${text}`, true, defaultMessage || text, intl) as string;

    const makeTfaComponent = () => {
        // TODO: remove "statusTfa === 1"
        if (statusTfa === undefined || statusTfa === 0 || statusTfa === 1) {
            return <EnableTfaAction isButton={true} plain={plain} refreshInitialState={refreshInitialState} />;
        } else if (statusTfa === 2) {
            return <div style={{ display: 'flex' }}>
                <div className={styles.is_set} onClick={handleSwitchSecurity}>
                    <LockOutlined />
                    <span style={{ marginLeft: '6px' }}>{plain('2FA')}</span>
                </div>
                {TfaButtonTooltipIcon(plain('2 Factor Authentication is enabled.'))}
            </div>;
        }
        else if (statusTfa === 3) {
            return <div style={{ display: 'flex' }}>
                <RenewTfaButton plain={plain} />
                {TfaButtonTooltipIcon(plain('Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.'))}
            </div>;
        } else {
            return null;
        }
    };
    const tfaComponent = makeTfaComponent();

    return tfaComponent != undefined &&
        <>
            <span className={styles.tfa} key={'tfa'}>
                {tfaComponent}
            </span>
        </>;
}

type TfaCodeComponentProps = {
    form: FormInstance<any>;
    setIsCodeReady?: (isReady: boolean) => void;
    width?: number | 'sm' | 'md' | 'xl' | 'xs' | 'lg';
    isBackupCodeAcceptable: boolean;
    autoFocus?: boolean;
    onChange?: (v: string) => void;
    err?: string;
    setErr?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const TfaCodeComponent: React.FC<TfaCodeComponentProps> = ({ form, setIsCodeReady, width, isBackupCodeAcceptable, autoFocus, onChange, err, setErr }) => {
    const inputRef = useRef<InputRef>(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    useEffect(() => {
        if (autoFocus) {
            inputRef.current?.focus();
        }
    }, [autoFocus]);

    const intl = useIntl();
    const plain = (text: string, defaultMessage = '') => t(`pages.account.tfa.${text}`, true, defaultMessage || text, intl) as string;

    const validateCodeInput = (rule: any, value?: string) => {
        if (value == undefined || value.length == 0) {
            return Promise.resolve();
        }

        const errMsg = plain(isBackupCodeAcceptable ? 'The code must be 6, 8, 10 digits.' : 'The code must be 6 or 8 digits.');
        if (value.length > (isBackupCodeAcceptable ? 10 : 8)) {
            return Promise.reject(errMsg);
        }

        if (isFocused) {
            const regex = /^\d+$/;
            if (!regex.test(value)) {
                if (setIsCodeReady) {
                    setIsCodeReady(false);
                }
                return Promise.reject(errMsg);
            } else {
                if ((isBackupCodeAcceptable ? [6, 8, 10] : [6, 8]).includes(value.length)) {
                    if (setIsCodeReady) {
                        setIsCodeReady(true);
                    }
                }
            }
            return Promise.resolve();
        }
        if (value) {
            const regex = isBackupCodeAcceptable ? /^\d{6}$|^\d{8}$|^\d{10}$/ : /^\d{6}$|^\d{8}$/;
            if (!regex.test(value)) {
                if (setIsCodeReady) {
                    setIsCodeReady(false);
                }
                return Promise.reject(errMsg);
            } else {
                if (setIsCodeReady) {
                    setIsCodeReady(true);
                }
            }
        }
        if (err) {
            return Promise.reject(err);
        }
        return Promise.resolve();
    };

    const handleErr = () => {
        if (err == undefined) {
            return;
        }
        const v = form.getFieldValue(['tfa_code']) as string | undefined;
        if (v && v.length > 0) {
            form.validateFields(['tfa_code']);
        }
    }

    const handleBlur = () => {
        setIsFocused(false);
        const v = form.getFieldValue(['tfa_code']) as string | undefined;
        if (v && v.length > 0) {
            form.validateFields(['tfa_code']);
        }
    }

    const handlePressEnter = () => {
        setIsFocused(false);
        form.submit();
    }

    return <>
        <ProForm.Item
            name="tfa_code"
            label={<div className={styles.inputLabel}>{plain('2FA code')}</div>}
            rules={
                [
                    { required: true, message: plain('Please enter your 2FA code') },
                    { validator: validateCodeInput }
                ]
            }
        >
            <Input
                style={{ width: width }}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                onPressEnter={handlePressEnter}
                ref={inputRef}
                placeholder={plain(isBackupCodeAcceptable ? 'Enter your 2FA code or backup code' : 'Enter your 2FA code')}
                onChange={(e: any) => {
                    setErr?.(undefined);
                    onChange?.(e.target.value);
                }}
            />
        </ProForm.Item>
        {handleErr()}
    </>
}
